import React, { createContext, useContext, useState, useEffect } from 'react';
import { register as registerApi, login as loginApi } from '../models/AuthModel';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedAccessToken = localStorage.getItem('accessToken');
    if (storedUser) {
      setUser(storedUser);
    }
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
  }, [user]);

  const login = async (email, password) =>  {
    // Implement login function here
    const result = await loginApi(email, password);
    const userData = result.data.user;
    const accessToken = result.data.accessToken;
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('accessToken', accessToken);
    if (result.success) {
      setUser(result.data.user);
    }
    return result;
  };

  const register = async (email, password) => {
    const result = await registerApi(email, password);
    const userData = result.data.user;
    const accessToken = result.data.accessToken;
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('accessToken', accessToken );
    if (result.success) {
      setUser(result.data.user);
    }
    return result;
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    setUser(null);
    setAccessToken(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
