import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ExploreView.css';
import WriterModel from '../models/WriterModel';

const ExploreView = () => {
  const [writers, setWriters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWriters, setFilteredWriters] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchWriters = useCallback(async () => {
    if (!hasMore) return;
    setIsLoading(true);
    const writerModel = new WriterModel();
    const data = await writerModel.fetchWriters(page);
    setWriters(prevWriters => [...prevWriters, ...data]);
    setFilteredWriters(prevWriters => [...prevWriters, ...data]);
    setPage(prevPage => prevPage + 1);
    setIsLoading(false);
    if (data.length === 0) setHasMore(false);
  }, [page, hasMore]);

  useEffect(() => {
    fetchWriters();
  }, [fetchWriters]);

  useEffect(() => {
    const results = writers.filter(writer =>
      writer.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWriters(results);
  }, [searchTerm, writers]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) return;
    fetchWriters();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  const handleWriterClick = (writerId) => {
    navigate('/writer-details', { state: { writerId } }); // Navigate with state
  };

  return (
    <div className="explore-container">
      <input
        type="text"
        className="search-bar"
        placeholder="Search creators..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <ul className="writer-list">
        {filteredWriters.map((writer) => (
          <li 
            key={writer.id} 
            className="writer-item"
            onClick={() => handleWriterClick(writer.id)} // Handle click
          >
            <img src={writer.profileImage} alt={writer.name} className="writer-profile-image-explore" />
            <div className="writer-info-explore">
              <span className="writer-name-explore">{writer.name}</span>
              <span className="writer-age-explore">{writer.age} years old</span>
            </div>
          </li>
        ))}
      </ul>
      {isLoading && <p>Loading...</p>}
    </div>
  );
};

export default ExploreView;
