import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BlogItemView.css';
import ImageSlider from './ImageSlider';
import MainContentModel from '../models/MainContentModel';
import CommentView from './CommentView';
import { useAuth } from '../context/AuthContext';

const BlogItemView = ({ item, isFeedView = true }) => {
    const [isFollowed, setIsFollowed] = useState(item.isFollowed);
    const [isLiked, setLiked] = useState(item.interactions && item.interactions.length > 0 && item.interactions[0].likeImpression === 1);
    const mainContentModel = new MainContentModel();
    const [showCommentView, setShowCommentView] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleLike = async (post) => {
       

        if (user) {
            const storedAccessToken = localStorage.getItem('accessToken');
            const user = localStorage.getItem('user');
            const json = JSON.parse(user);
            const postId = post.id;
            const writerId = post.characterId;
            var impression = !isLiked;
            const response = await mainContentModel.likePost(postId, writerId, impression);
            var interactions = response;
            var liked = interactions && interactions.length > 0 && interactions[0].likeImpression === 1;
            setLiked(liked);
        } else {
            navigate("/signin")
        }
    };

    const handleComment = () => {
        if (user) {
            setShowCommentView(true);
        } else {
            navigate("/signin")
        }
    };

    const handleCloseCommentView = () => {
       
        setShowCommentView(false);
    };

    const handleProfileClick = () => {
        if (user) {
            navigate('/writer-details', { state: { writerId: item.characterId } }); // Use state to pass writerId
        } else {
            navigate("/signin")
        }
        
    };

    const handleItemClick = () => {
        navigate('/post-details', { state: { postId: item.id } }); // Navigate to PostDetailsView with postId
    };

    function formatDate(createdAt) {
        const now = new Date();
        const createdDate = new Date(createdAt);
        let diff = now.getTimezoneOffset();
        const diffInSeconds = Math.floor((now - createdDate ) / 1000) + diff*60;
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);

        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const isToday = createdDate >= todayStart;

        const isThisYear = createdDate.getFullYear() === now.getFullYear();

        const options = { month: 'short', day: 'numeric' };
        if (!isThisYear) {
            options.year = 'numeric';
        }

        if (isToday) {
            if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
            }
        } else {
            return createdDate.toLocaleDateString(undefined, options);
        }
    }

    const handleFollowToggle = async (writerId, currentStatus) => {
        if (user) {
            try {
                if (currentStatus) {
                    await mainContentModel.unfollowWriter(writerId);
                } else {
                    await mainContentModel.followWriter(writerId);
                }
                setIsFollowed(!currentStatus); // Update the state to reflect the new follow status
            } catch (error) {
                console.error('Error toggling follow status:', error);
            }
        } else {
            navigate("/signin")
        }
        
    };

    return (
        <div className="blog-item-view">
            <div className="content-header" onClick={handleProfileClick}>
                <img src={item.profileImage} alt="Profile" className="profile-image" />
                <div className="character-info">
                    <span className="character-name">{item.name}</span>
                    <span className="post-date">{formatDate(item.createdAt)}</span>
                </div>
                {isFeedView &&
                    <button
                        className="follow-button"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent click event from bubbling up
                            handleFollowToggle(item.characterId, isFollowed);
                        }}
                    >
                        {isFollowed ? <img src={require('../icons/user-check.svg').default} alt='Follow' /> : <img src={require('../icons/user-plus.svg').default} alt='Unfollow' />}
                    </button>
                }
            </div>
            <div className="images-container">
                <ImageSlider images={item.images} />
            </div>
            <div className="post-content">
                <p>{item.message}</p>
            </div>
            <div className="post-actions">
                <button className="like-button" onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from bubbling up
                    handleLike(item);
                }}>
                    {isLiked ? 
                        <img src={require('../icons/filled-heart.svg').default} alt='Like' /> :
                        <img src={require('../icons/heart.svg').default} alt='Unlike' />
                    }
                </button>
                <button className="comment-button" onClick={(e) => {
                    e.stopPropagation(); // Prevent click event from bubbling up
                    handleComment();
                }}>
                    <img src={require('../icons/message-circle.svg').default} alt='Comment' />
                </button>
            </div>
            {showCommentView && <CommentView post={item} onClose={handleCloseCommentView} />}
        </div>
    );
};

export default BlogItemView;
