import axios from 'axios';
import { useState, useEffect } from 'react';

class CommentViewModel {
    constructor(post) {
        this.post = post
        this.writerId = post.characterId;
        this.messages = [];
        this.newMessage = '';
    }
  
    async fetchMessages() {
        var token = localStorage.getItem('accessToken');
        try {
            // Mock API call
            // const response = await fetch(`/api/messages/${this.writerId}`);
            // const fetchedMessages = await response.json();
            // this.messages = fetchedMessages;
            // return this.messages;

            if (token) {
                
                const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/comments/history", 
                  {postId: this.post.id, characterId: this.post.characterId},
                  {
                    headers: {
                        Authorization: `Bearer `+token,
                    },
                  }
                );
                
                this.messages = response.data.comments;
                return this.messages;
                
            } else {
                return [];
            }


        } catch (error) {
            console.error('Error fetching messages:', error);
            throw error;
        }
    }
  
    async sendMessage(message) {
      if (message.trim()) {
        var token = localStorage.getItem('accessToken');
        try {
               if (token) {
               
                const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/delayedComments/create", 
                    {
                        postId: this.post.id, 
                        characterId: this.post.characterId,
                        pictureId: "",
                        messageType: "text",
                        requestType: "comment",
                        query: message
                    },
                    {
                    headers: {
                        Authorization: `Bearer `+token,
                    },
                }
                );
                var sentMessage = {isFromBot: 0, message: message}
                this.messages.push(sentMessage);
                this.newMessage = '';
                return this.messages;
                
            } 
        }
        catch (error) {
            console.error('Error fetching messages:', error);
            throw error;
        }


      }
    }
  
    setNewMessage(message) {
      this.newMessage = message;
    }
  }
  
  export default CommentViewModel;
  