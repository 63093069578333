// src/models/ChatMessagesViewModel.js
import axios from 'axios';

class ChatMessagesViewModel {
    async fetchChatHistory(writerId) {
        var token = localStorage.getItem('accessToken');
        try {
            if (token) { 
                const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/lailaMessages/all", 
                  {characterId: writerId},
                  {
                    headers: {
                        Authorization: `Bearer `+token,
                    },
                  }
                );
               
                return response.data.messages;
            } else {
                return [];
            }
            
        } catch (error) {
            console.error('Error fetching chat history:', error);
            throw error;
        }
    }

    async sendMessage(writerId, message) {
        var token = localStorage.getItem('accessToken');
        try {
            const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/delayedMessages/messageCreate", 
                {characterId: writerId, query: message, messageType: "text"},
                {
                headers: {
                    Authorization: `Bearer `+token,
                },
                }
            );

            var sentMessage = {isFromBot: 0, message: message}
            return sentMessage
            
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }
}

export default ChatMessagesViewModel;
