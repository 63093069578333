import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ChatView.css';
import ChatMessagesView from './ChatMessagesView';
import messageFilledIcon from '../icons/message-filled.svg'; // Adjust path as needed

const ChatView = () => {
    const location = useLocation();
    const navigate = useNavigate(); 
    const { writerId, writerProfileImage, writerName } = location.state ?  location.state : {};
    const [showChatMessages, setShowChatMessages] = useState(false);

    const handleChatClick = () => {
        setShowChatMessages(true);
    };

    const handleCloseChat = () => {
        setShowChatMessages(false);
    };

    useEffect(() => {
        if (location.state === null) {
          navigate("/");
        }
      }, []);

    return (
        <div className="chat-view-container">
            <div className="profile-container">
                <img src={writerProfileImage} alt="Writer" className="profile-image" />
                <h2 className="writer-name">{writerName}</h2>
                <button className="writer-chat-button" onClick={handleChatClick}>
                    Let's Chat
                </button>
            </div>
            {showChatMessages && <ChatMessagesView writerId={writerId} onClose={handleCloseChat} />}
        </div>
    );
};

export default ChatView;
