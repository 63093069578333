import React, { useEffect, useState, useRef } from 'react';
import './ChatMessagesView.css';
import ChatMessagesViewModel from '../models/ChatMessagesViewModel';

const ChatMessagesView = ({ writerId, onClose }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const chatMessagesViewModel = new ChatMessagesViewModel();
    const chatMessagesRef = useRef(null);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const data = await chatMessagesViewModel.fetchChatHistory(writerId);
                setMessages(data);
            } catch (error) {
                setError('Failed to load chat messages.');
            }
        };

        fetchMessages();
    }, [writerId]);

    useEffect(() => {
        setShow(true); // Trigger the animation

        const handleClickOutside = (event) => {
            if (chatMessagesRef.current && !chatMessagesRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    const handleSendMessage = async () => {
        if (newMessage.trim()) {
            try {
                const data = await chatMessagesViewModel.sendMessage(writerId, newMessage);
                setMessages([...messages, data]);
                setNewMessage('');
            } catch (error) {
                setError('Failed to send message.');
            }
        }
    };

    return (
        <div className={`chat-messages-view ${show ? 'show' : ''}`} ref={chatMessagesRef}>
            <div className="chat-messages-header">
                <button className="close-button" onClick={onClose}>
                    <img src={require('../icons/x-circle.svg').default} alt='Close' /> 
                </button>
            </div>
            <div className="chat-messages-content">
                {error && <p>{error}</p>}
                {messages.map((message, index) => (
                    <div key={index} className={`chat-message ${message.isFromBot ? 'writer-message' : 'user-message'}`}>
                        {message.message}
                    </div>
                ))}
            </div>
            <div className="chat-messages-input">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default ChatMessagesView;
