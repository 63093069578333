import React from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import homeIcon from '../icons/home.svg';
import profileIcon from '../icons/users.svg';
import bookmarkIcon from '../icons/bookmark.svg';
import searchIcon from '../icons/search.svg';
import appIcon from '../icons/alaina.png'; // Import the app icon

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/signin');
  };

  return (
    <header className="header-container">
      <div className="app-icon-container">
        <img src={appIcon} alt="Alaina App Icon" className="app-icon" /> {/* Use the app icon */}
      </div>
      <nav className="nav">
        <Link to="/">
          <img src={homeIcon} alt="Home" className="nav-icon" />
          
        </Link>
        {user ? (
          <>
           
           <Link to="/explore">
              <img src={searchIcon} alt="Search" className="nav-icon" />
             
            </Link>

            <Link to="/update">
              <img src={bookmarkIcon} alt="Bookmark" className="nav-icon" />
             
            </Link>
            
            <Link to="/profile">
            <img src={profileIcon} alt="Bookmark" className="nav-icon" />
            </Link>
           
          </>
        ) : (
          <Link to="/signin">
            Login
          </Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
