import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import './ImageSlider.css'; // Ensure this matches your CSS file
import { useAuth } from '../context/AuthContext';

const ImageSlider = ({ images }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleImageClick = (image) => {
    if (user) {
        navigate('/image-viewer', { state: { image } });
    } else {
        navigate("/signin")
    }
    
  };

  return (
    <div className="image-slider-container">
      {images.length === 1 ? (
        <div key={1} onClick={() => handleImageClick(images[0])}>
          <img src={images[0].imageUrl} alt={`Post Image 1`} className="post-image" />
        </div>
      ) : (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} onClick={() => handleImageClick(image)}>
              <img src={image.imageUrl} alt={`Post Image ${index + 1}`} className="post-image" />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ImageSlider;
