import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ImageViewer.css'; // Ensure this matches your CSS file


const ImageViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { image } = location.state || {};


  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleTagClick = () => {
    
    if (image.products[0].productUrl) {
        window.open(image.products[0].productUrl, '_blank');
    }
    
  };

  if (!image) {
    return <p>No image to display.</p>;
  }

  return (
    <div className="image-viewer">
      <button className="back-button" onClick={handleBack}>Back</button>
      <div className="image-container">
        <img src={image.imageUrl} alt="Viewed Image" className="viewed-image" />
        { image.products && image.products[0] && image.products[0].productId &&
            <button className="tag-button" onClick={handleTagClick}>
                <img src={require('../icons/tag.svg').default} alt="Tag" />
            </button>
        }
        
      </div>
    </div>
  );
};

export default ImageViewer;
