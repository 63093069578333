import React from 'react';
import { useAuth } from '../context/AuthContext';
import './UserProfileView.css';
import { useNavigate } from 'react-router-dom';

const UserProfileView = () => {
  const { user, logout } = useAuth();
  const history = useNavigate();
  const handleLogout = () => {
    logout();
    history('/');
  };

  return (
    <div className="user-profile-view">
      {user ? (
        <div className="profile-container">
          <h2>{JSON.parse(localStorage.getItem('user')).userName}</h2>
          <p>{user.email}</p>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      ) : (
        <p>Please log in to see your profile.</p>
      )}
    </div>
  );
};

export default UserProfileView;
