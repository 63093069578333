import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { validateEmail } from '../models/AuthModel';
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { register } = useAuth();
  const history = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }

    const result = await register(email, password);
    if (result.success) {
      history('/');
    } else {
      setError(result.message);
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2>Sign Up</h2>
        {error && <div className="error-message">{error}</div>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Sign Up</button>
        <div className="signin-link">
          Already have an account? <Link to="/signin">Sign In</Link>
        </div>
        <div className="disclaimer">
          By clicking on Sign Up to create an account, you agree to the 
          <a href="https://www.alaina.app/privacy.html" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>,
          <a href="https://www.alaina.app/terms.html" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>, and 
          <a href="https://www.alaina.app/eula.html" target="_blank" rel="noopener noreferrer"> EULA</a>.
        </div>
      </form>
    </div>
  );
};

export default SignUp;
