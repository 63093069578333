import React, { useState, useEffect } from 'react';
import './CommentView.css';
import CommentViewModel from '../models/CommentViewModel';

const CommentView = ({ post, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isSlidingOut, setIsSlidingOut] = useState(false);
    const [viewModel] = useState(new CommentViewModel(post));
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');



    const handleClose = () => {
        setIsSlidingOut(true);
        setTimeout(() => {
            setIsVisible(false);
            onClose();
        }, 300); // Match the animation duration
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('comment-view-overlay')) {
                handleClose();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const fetchMessages = async () => {
          try {
            const fetchedMessages = await viewModel.fetchMessages();
            setMessages(fetchedMessages);
          } catch (error) {
            console.error('Error fetching messages:', error);
          }
        };
    
        fetchMessages();
      }, [viewModel]);


      const handleSendMessage = async () => {
        if (newMessage.trim().length > 0) {
            try {
                const sentMessage = await viewModel.sendMessage(newMessage);
                setMessages([...messages, sentMessage]);
                setNewMessage('');
              } catch (error) {
                console.error('Error sending message:', error);
              }
        }
      
    };


    const handleInputChange = (e) => {
        const message = e.target.value;
        viewModel.setNewMessage(message);
        setNewMessage(message);
      };


    return (
        isVisible && (
            <div className="comment-view-overlay">
                <div className={`comment-view-container ${isSlidingOut ? 'slide-out' : ''}`}>
                    <div className="messages-container">
                    {messages.map((msg, index) => (
                        msg.message ?
                        <div key={index} className={`message ${msg.isFromBot ? 'writer-message' : 'user-message'}`}>
                            {msg.message}
                        </div>
                        :<></>
                    ))}
                    </div>
                    <div className="input-container">
                    <input
                        type="text"
                        value={newMessage}
                        onChange={handleInputChange}
                        placeholder="Type a message..."
                        />
                        <button onClick={handleSendMessage}>Send</button>
                                </div>
                    </div>
            </div>
        )
    );
};

export default CommentView;
