import axios from 'axios';
import { useState, useEffect } from 'react';

class WriterModel {
    
    async fetchWriters(page = 1, limit = 10) {
        var token = localStorage.getItem('accessToken');
        
        try {
    
          if (token) {
            const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/lailaCharacters/all", 
              {page: page},
              {
                headers: {
                    Authorization: `Bearer `+token,
                },
              }
            );
            // Transform the data to match your view's requirements
            const items = response.data.characters
    
            return items;
          } else {
            return [];
          } 

          
          
          
        } catch (error) {
          console.error('Failed to fetch items:', error);
          return [];
        }
      }



  }

  
  
  export default WriterModel;
  