import React, { useEffect, useState, useCallback } from 'react';
import './MainContent.css';
import MainContentModel from '../models/MainContentModel';
import BlogItemView from './BlogItemView';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


const MainContent = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const mainContentModel = new MainContentModel();
  const { isAuthenticated } = useAuth();
  const history = useNavigate();

  
  const fetchItems = useCallback(async (page) => {
    setIsLoading(true);
    try {
      const data = await mainContentModel.fetchItems(page);
      setItems(prevItems => [...prevItems, ...data]);
      setPage(prevPage => prevPage + 1);
      setIsLoading(false);
    } catch (error) {
      setError('Failed to load data.');
      setIsLoading(false);
    }
  }, [mainContentModel]);

  const followWriter = useCallback(async (writerId) => {
    
   
    try {
      const data = await mainContentModel.followWriter(writerId);
      
    } catch (error) {
      setError('Failed to load data.');
     
    }
  }, []);

  const unfollowWriter = useCallback(async (writerId) => {
   
    try {
      const data = await mainContentModel.unfollowWriter(writerId);
      
    } catch (error) {
      setError('Failed to load data.');
     
    }
  }, []);



  useEffect(() => {
    fetchItems(page);
  }, []);

  const handleFollowToggle = (writerId, isFollowed) => {
    if (isFollowed) {
      unfollowWriter(writerId);
    } else {
      followWriter(writerId);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) {
      return;
    }
    fetchItems(page);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  if (error) return <p>{error}</p>;

  return (
    <main className="main-container">
      {items.map((item) => (
        <BlogItemView
          key={item.id}
          item={item}
          // onLike={handleLike}
          // onComment={handleComment}
        />
      ))}
      {isLoading && <p>Loading...</p>}
    </main>
  );
};

export default MainContent;
