import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import './WriterDetailsView.css'; 
import WriterDetailsViewModel from '../models/WriterDetailsViewModel';
import ImageSlider from './ImageSlider';
import BlogItemView from './BlogItemView'; 
import userCheckIcon from '../icons/user-check.svg'; // Adjust path as needed
import userPlusIcon from '../icons/user-plus.svg'; // Adjust path as needed
import messageFilledIcon from '../icons/message-filled.svg'; // Adjust path as needed

const WriterDetailsView = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize useNavigate
    // const { writerId } = location.state;
    const { writerId } = location.state ?  location.state : "0";
    const [writer, setWriter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false); // State for follow/unfollow
    const writerDetailsViewModel = new WriterDetailsViewModel();


    useEffect(() => {
        if (location.state === null) {
          navigate("/");
        }
      }, []);


    useEffect(() => {
        
        const fetchWriterDetails = async () => {
            try {
                const data = await writerDetailsViewModel.fetchWriterDetails(writerId);
                setWriter(data);
                setIsLoading(false);
                setIsFollowing(data.profile.isFollowed);
            } catch (error) {
                setError('Failed to load writer details.');
                setIsLoading(false);
            }
        };

        fetchWriterDetails();
    }, [writerId]);

    const handleFollowUnfollow = async () => {
        try {
            if (isFollowing) {
                await writerDetailsViewModel.unfollowWriter(writerId);
                setIsFollowing(false);
            } else {
                await writerDetailsViewModel.followWriter(writerId);
                setIsFollowing(true);
            }
        } catch (error) {
            console.error('Failed to follow/unfollow writer', error);
        }
    };

   
    const handleChat = () => {
        navigate('/chat', { state: { writerId, writerProfileImage: writer.profile.profileImage, writerName: writer.profile.name} });
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="writer-details-container">
            {writer && (
                <div>
                    <img src={writer.profile.coverImageUrl} alt="Cover" className="cover-image" />
                    <div className="writer-details">
                        <img src={writer.profile.profileImage} alt="Profile" className="writer-profile-image" />
                        <div className="writer-details-info">
                            <h1 className="writer-name">{writer.profile.name}</h1>
                            <p className="writer-occupation">{writer.profile.occupation}</p>
                        </div>
                        <div className="action-buttons">
                            <button className="follow-button" onClick={handleFollowUnfollow}>
                                <img src={isFollowing ? userCheckIcon : userPlusIcon} alt={isFollowing ? 'Unfollow' : 'Follow'} />
                            </button>
                            <button className="chat-button" onClick={handleChat}>
                                <img src={messageFilledIcon} alt="Chat" />
                            </button>
                        </div>
                    </div>
                    <div className="writer-bio-age">
                        <h2>About</h2>
                        <p className="writer-bio">{writer.profile.description}</p>
                    </div>
                    {writer.pictures && writer.pictures.length > 0 && (
                        <div className="photos-section">
                            <h2>Photos</h2>
                            <div className="images-container">
                                <ImageSlider images={writer.pictures} />
                            </div>
                        </div>
                    )}
                    {writer.posts && writer.posts.length > 0 && (
                        <div>
                            <div>
                                <h2>Stories</h2>
                            </div>
                            <div className="stories-section">
                                <div className="stories-list">
                                    {writer.posts.map((story) => (
                                        <BlogItemView key={story.id} item={story} isFeedView={false} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default WriterDetailsView;
