import React from 'react';
import './MessageItemView.css';
import { useNavigate } from 'react-router-dom'; 

const MessageItemView = ({ item }) => {


    const navigate = useNavigate(); // Initialize navigate

    const handleClick = () => {
        // Navigate to ChatView, passing writerId and profileImage as state
        navigate('/chat', { state: { writerId: item.character.id, writerProfileImage: item.character.profileImage, writerName: item.character.name} });
    };
    
    const formatDate = (createdAt) => {
        const now = new Date();
        const moment = require('moment');
        const dateString = createdAt;
        const formatString = "MM:DD:YYYY hh:mm:ss";

        const createdDate = moment(dateString, formatString).toDate();
        let diff = now.getTimezoneOffset();
        const diffInSeconds = Math.floor((now - createdDate ) / 1000) + diff * 60;
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const isToday = createdDate >= todayStart;
        const isThisYear = createdDate.getFullYear() === now.getFullYear();
      
        const options = { month: 'short', day: 'numeric' };
        if (!isThisYear) {
            options.year = 'numeric';
        }
      
        if (isToday) {
            if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
            } else {
                return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
            }
        } else {
            return createdDate.toLocaleDateString(undefined, options);
        }
    };

    return (
        <div className="message-item-view" onClick={handleClick}>
            <img src={item.profileImage} alt={item.character.name} className="writer-profile-image-message" />
            <div className="message-info">
                <span className="writer-name">{item.character.name}</span>
                <p className="message-text">{item.message}</p>
                <span className="message-time">{formatDate(item.createdAt)}</span>
            </div>
        </div>
    );
};

export default MessageItemView;
