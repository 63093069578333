import React, { useEffect, useState } from 'react';
import './UpdateView.css';
import UpdateViewModel from '../models/UpdateViewModel';
import BlogItemView from './BlogItemView';
import MessageItemView from './MessageItemView'; // Import MessageItemView

const UpdateView = () => {
    const [updates, setUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const updateViewModel = new UpdateViewModel();

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const data = await updateViewModel.fetchUpdates();
                setUpdates(data);
                setIsLoading(false);
            } catch (error) {
                setError('Failed to load updates.');
                setIsLoading(false);
            }
        };

        fetchUpdates();
    }, []);

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="update-view-container">
            <h2>Updates</h2>
            <ul className="update-list">
                {updates.map((update) => (
                    <li key={update.id} className="update-item">
                        {update.type === 'post' ? (
                            <BlogItemView item={update.post} isFeedView={false} />
                        ) : update.type === 'message' ? (
                            <MessageItemView item={update.message} />
                        ) : (
                            <p>Unknown update type</p>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UpdateView;
