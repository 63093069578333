import axios from 'axios';
import { useState, useEffect } from 'react';

class MainContentModel {
  
  async fetchItems(page = 1, limit = 10) {
    var token = localStorage.getItem('accessToken');

    try {

      if (token == null) {
        const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/post/getPages", 
          {page: page},
        
        );
        // Transform the data to match your view's requirements
        const items = response.data.posts
       
        return items;
      } else {
         

        const response = await axios.post("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/post/getPages", 
          {page: page},
          {
            headers: {
                Authorization: `Bearer `+token,
            },
          }
        );
        // Transform the data to match your view's requirements
        const items = response.data.posts
        return items;
      }
      
      
      
    } catch (error) {
      console.error('Failed to fetch items:', error);
      return [];
    }
  }

  async followWriter(writerId)  {
    var token = localStorage.getItem('accessToken');

    try {
      const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/follow/create`,
      {characterId: writerId},
      {
        headers: {
            Authorization: `Bearer `+token,
        },
        }
      );
     
      
    } catch (error) {
      console.error('Failed to follow writer', error);
    }
  };

  async unfollowWriter (writerId) {
    var token = localStorage.getItem('accessToken');
    try {
      const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/follow/unfollow`,
        {characterId: writerId},
        {
          headers: {
              Authorization: `Bearer `+token,
          },
          }
      );
     
      
      
    } catch (error) {
      console.error('Failed to unfollow writer', error);
    }
  };

  async likePost(postId, writerId, impression) {
    var token = localStorage.getItem('accessToken');
    try {
      const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/interactions/make`,
        {characterId: writerId, postId: postId, type: "liking", impression: impression},
        {
          headers: {
              Authorization: `Bearer `+token,
          },
          }
      );
     
      const items = response.data.interactions

      return items;
      
    } catch (error) {
      console.error('Failed to likePost post', error);
    }
  };
}

export default MainContentModel;


// export function useMainContentModel() {
//   const [items, setItems] = useState([]);

//   useEffect(() => {
//     // Fetch initial data here
//     fetchItems();
//   }, []);

//   const fetchItems = async () => {
//     try {
//       const response = await axios.get('/api/items');
//       setItems(response.data);
//     } catch (error) {
//       console.error('Failed to fetch items', error);
//     }
//   };

  // const followWriter = async (writerId) => {
  //   try {
  //     await axios.post(`/api/follow/${writerId}`);
  //     updateFollowStatus(writerId, true);
  //   } catch (error) {
  //     console.error('Failed to follow writer', error);
  //   }
  // };

  // const unfollowWriter = async (writerId) => {
  //   try {
  //     await axios.post(`/api/unfollow/${writerId}`);
  //     updateFollowStatus(writerId, false);
  //   } catch (error) {
  //     console.error('Failed to unfollow writer', error);
  //   }
  // };

//   const updateFollowStatus = (writerId, isFollowed) => {
//     setItems(items.map(item =>
//       item.id === writerId ? { ...item, isFollowed } : item
//     ));
//   };

//   return {
//     items,
//     followWriter,
//     unfollowWriter
//   };
// }
