import axios from 'axios';

class WriterDetailsViewModel {
    async fetchWriterDetails(writerId) {
        var token = localStorage.getItem('accessToken');
        try {
            const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/lailaCharacters/characterById`,
            {characterId: writerId},
            {
              headers: {
                  Authorization: `Bearer `+token,
              },
            }
            );
            return response.data.character;
        } catch (error) {
            throw new Error('Error fetching writer details');
        }
    }

    async followWriter(writerId)  {
        var token = localStorage.getItem('accessToken');
    
        try {
          const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/follow/create`,
          {characterId: writerId},
          {
            headers: {
                Authorization: `Bearer `+token,
            },
            }
          );
         
          
        } catch (error) {
          console.error('Failed to follow writer', error);
        }
      };
    
      async unfollowWriter (writerId) {
        var token = localStorage.getItem('accessToken');
        try {
          const response = await axios.post(`https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/follow/unfollow`,
            {characterId: writerId},
            {
              headers: {
                  Authorization: `Bearer `+token,
              },
              }
          );
         
          
          
        } catch (error) {
          console.error('Failed to unfollow writer', error);
        }
      };
}

export default WriterDetailsViewModel;
