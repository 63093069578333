import axios from 'axios';

const API_URL = 'https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod'; // Replace with your actual API URL

const validateEmail = (userName) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(userName).toLowerCase());
};

const register = async (userName, password, firstName = "", lastName = "") => {
  try {
    const response = await axios.post(`${API_URL}/register`, { userName, password, firstName, lastName });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, message: error.response ? error.response.data.message : 'An error occurred' };
  }
};

const login = async (userName, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { userName, password });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: error.response ? error.response.data.message : 'An error occurred' };
    }
  };

export { validateEmail, register, login };
