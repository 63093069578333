import axios from 'axios'; // Adjust if you're using a different method for API calls

class UpdateViewModel {
    async fetchUpdates() {
        var token = localStorage.getItem('accessToken');
        try {
               if (token) {
               
                const response = await axios.get("https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/updates/all", 
                    {
                    headers: {
                        Authorization: `Bearer `+token,
                    },
                }
                );
               
                return response.data.updates
            } 
        }
        catch (error) {
            console.error('Error fetching messages:', error);
            throw error;
        }

    }
}

export default UpdateViewModel;
