import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ExploreView from './components/ExploreView';
import WriterDetailsView from './components/WriterDetailsView';
import ChatView from './components/ChatView';
import UpdateView from './components/UpdateView';
import ImageViewer from './components/ImageViewer';
import UserProfileView from './components/UserProfileView';

const App = () => (
  <AuthProvider>
    <Router>
      <Header />
      <div className="content-wrapper">
        <Routes>
          <Route path="/" exact element={<MainContent/>} />
          <Route path="/signin" element={<SignIn/>} />
          <Route path="/signup" element={<SignUp/>} />
          <Route path="/writer-details" element={<WriterDetailsView />} /> 
          <Route path="/explore" element={<ExploreView />} />
          <Route path="/update" element={<UpdateView />} />
          <Route path="/chat" element={<ChatView />} />
          <Route path="/image-viewer" element={<ImageViewer />} />
          <Route path="/profile" element={<UserProfileView />} />
          <Route path="*" element={<MainContent />} />
        </Routes>
      </div>
    </Router>
  </AuthProvider>
);

export default App;
